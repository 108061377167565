import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from '../Views/layout/main/main.component';
import {SubmainComponent} from '../Views/layout/submain/submain.component';
import {DashmainComponent} from '../Views/layout/dashmain/dashmain.component';
import { CasionSeriesMatchComponent } from '../casion-series-match/casion-series-match.component';
import { MarketResultComponent } from '../Views/Pages/marketResult/market-result.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: 'src/app/Views/Pages/login/login.module#LoginModule',
    },
    {
        path: 'register',
        loadChildren: 'src/app/Views/Pages/register/register.module#RegisterModule',
    },
    {
        path: 'otp',
        loadChildren: 'src/app/Views/Pages/otp/otp.module#OtpModule',
    },
    // {
    //     path: 'market-result',
    //     loadChildren: 'src/app/Views/Pages/marketResult/market-result.module#MarketResultsModule',
    // },
    {
        path: 'main',
        loadChildren: 'src/app/Views/layout/main/main.module#MainModule',
        component: MainComponent,
    },
    {
        path: 'dashmain',
        loadChildren: 'src/app/Views/layout/dashmain/dashmain.module#DashmainModule',
        component: DashmainComponent,
    },
    {
        path: 's',
        loadChildren: 'src/app/Views/layout/submain/submain.module#SubmainModule',
        component: SubmainComponent,
    },
    // {
    //     path: 'market-result',
    //     component: MarketResultComponent,
    // },
    {
        path: 'game/:sportId',
        component: CasionSeriesMatchComponent,
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
